<template>
  <div class="progressbar absolute" :ref="key"></div>
</template>

<script>
import utils from '@/utils';
export default {
  name: 'CircularProgress',
  props: {
    progress: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      progressBar: null,
      key: utils.createKey('circular-progress', 6)
    };
  },
  watch: {
    progress: {
      handler(newProgressValue) {
        this.updateProgress(newProgressValue);
      }
    }
  },
  methods: {
    updateProgress(progress) {
      this.progressBar.setAttribute('aria-valuenow', progress);
      this.progressBar.style.setProperty('--progress', progress + '%');
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.progressBar = this.$refs[this.key];

      this.progressBar.setAttribute('role', 'progressbar');
      this.progressBar.setAttribute('aria-valuenow', this.progress);
      this.progressBar.setAttribute('aria-live', 'polite');
    });
  }
};
</script>

<style lang="css" scoped>
@property --progress {
  syntax: '<length-percentage>';
  inherits: false;
  initial-value: 0%;
}

[role='progressbar'] {
  --size: 100%;
  --bar-width: 12px;
}

[role='progressbar'] {
  --color-dark-glare: white;
  --color-success: #2dd4bf;
  --color-success-dark: #2d7a59;

  font-size: 1rem;

  aspect-ratio: 1 / 1;
  background: conic-gradient(
    var(--color-success),
    var(--color-success) var(--progress),
    var(--color-dark-glare) 0%
  );
  border-radius: 50vmax;
  display: grid;
  place-items: center;
  transition: --progress 500ms linear;
}

[role='progressbar'] > span {
  display: none;
}

/* using aria-valuenow, to help enforce using it in the JS */
[role='progressbar']::after {
  content: attr(aria-valuenow) '%';
  background: white;
  width: calc(100% - var(--bar-width));
  aspect-ratio: 1;
  border-radius: inherit;
  display: grid;
  place-items: center;
}

/* if js is disabled */
.progressbar:not([role='progressbar']) {
  position: relative;
}

.progressbar:not([role='progressbar'])::after {
  display: none;
}

.progressbar:not([role='progressbar']) span {
  font-size: 1rem;
  text-align: center;
  color: var(--color-success-dark);
}
</style>
