<template>
  <div>
    <div class="flex items-center space-x-6">
      <div
        class="group relative flex aspect-square w-28 flex-none cursor-pointer items-center rounded-full bg-gray-100 hover:bg-gray-200"
      >
        <div
          v-if="catDetails.image"
          class="relative flex items-center justify-center"
          @click="showUpload"
        >
          <img
            :class="!catDetails.active ? 'grayscale' : ''"
            :src="getImageUrl(catDetails.image)"
            class="aspect-square w-28 flex-none rounded-full"
          />

          <circular-progress
            class="aspect-square w-[7.1rem]"
            v-if="isUploading"
            :progress="percentCompleted"
          />
        </div>
        <div
          v-else
          class="relative flex aspect-square w-32 items-center justify-center text-4xl text-gray-400"
        >
          <font-awesome-icon
            :icon="['fal', 'camera']"
            class="flex group-hover:hidden"
          />

          <label
            v-if="!catDetails.image"
            @click="showUpload"
            class="absolute inset-0 !mb-0 !hidden cursor-pointer items-center justify-center !text-4xl leading-none group-hover:!flex"
          >
            <font-awesome-icon :icon="['far', 'plus']" />
          </label>
        </div>
        <input
          type="file"
          class="hidden"
          @change="handleImageUpload"
          accept="image/jpg, image/jpeg, image/png"
          id="file-upload"
          ref="file-upload"
        />
      </div>
      <div class="text-xs text-gray-500">
        <div class="mb-2">Images must be...</div>
        <div>- less than 10Mb in file size</div>
        <div>- JPG or PNG file formats</div>
        <div
          v-if="catDetails.image"
          class="mt-4 flex h-9 items-stretch space-x-4 text-base"
        >
          <div
            class="flex flex-grow items-center justify-center rounded bg-gray-100 hover:cursor-pointer hover:bg-gray-200"
            @click.stop="showUpload"
          >
            Update
          </div>
          <div
            class="tippy flex aspect-square h-full items-center justify-center rounded bg-red-400 text-white hover:cursor-pointer hover:bg-red-500"
            @click="removeUpload"
            data-tippy-content="Remove"
          >
            <font-awesome-icon :icon="['far', 'trash-can-xmark']" />
          </div>
        </div>
        <div v-else class="mt-4 flex h-9 items-stretch space-x-4 text-base">
          <div
            class="flex flex-grow items-center justify-center rounded bg-gray-100"
            :class="
              isUploading
                ? 'pointer-events-none opacity-50'
                : 'hover:cursor-pointer hover:bg-gray-200'
            "
            @click.stop="showUpload"
          >
            {{ isUploading ? 'Uploading...' : 'Choose an image...' }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-4 rounded bg-red-100 p-2 text-center text-sm text-red-400"
      v-if="error"
    >
      {{ error }}
    </div>
    <div class="text-xs italic text-gray-500" :class="error ? 'mt-4' : 'mt-8'">
      By uploading your cat's photo, you’re giving us purr-mission to feature
      them on our site and in our marketing materials.
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import { mapActions, mapMutations } from 'vuex';
import CircularProgress from '../../CircularProgress.vue';
export default {
  components: { CircularProgress },
  name: 'Upload',
  props: {
    catDetails: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      percentCompleted: 0,
      isUploading: false,
      error: null
    };
  },
  methods: {
    ...mapActions({
      uploadImage: 'uploadImage',
      removeImage: 'removeImage'
    }),
    ...mapMutations({
      updateCat: 'update_cat_details'
    }),
    closeModal: function () {
      this.$parent.$emit('make-close');
    },
    getImageUrl(image) {
      return image + '?tr=fo-cat,ar-1_1,w-128,f-webp';
    },
    removeUpload() {
      this.removeImage({ id: this.catDetails.id }).then(() => {
        this.updateCat({
          id: this.catDetails.id,
          value: '',
          key: 'image'
        });
        this.catDetails.image = '';
      });
    },
    showUpload() {
      // simulate a click event on the file upload input

      const elem = this.$refs['file-upload'];
      if (elem) {
        elem.click();
      }
    },
    handleImageUpload(event) {
      this.isUploading = true;
      this.error = null;
      const file = event.target.files[0];
      // get the file extension formt the file name
      const fileExtension = file.name.split('.').pop();
      const self = this;
      const fileName = `${this.catDetails.name}-${this.catDetails.id}-${Date.now()}.${fileExtension}`;

      const formData = new FormData();
      const config = {
        onUploadProgress: function (progressEvent) {
          self.percentCompleted = Math.round(progressEvent.progress * 100);
        }
      };

      formData.append('image', file);

      formData.append('filename', fileName);

      this.uploadImage({ id: this.catDetails.id, data: formData, config })
        .then((response) => {
          this.catDetails.image = response[0].image.url;
          this.isUploading = false;
          this.percentCompleted = 0;
          this.updateCat({
            id: this.catDetails.id,
            value: response[0].image.url,
            key: 'image'
          });
        })
        .catch((error) => {
          this.isUploading = false;
          console.log(error.response.data.error);
          this.error = error.response.data.error.message;
          this.percentCompleted = 0;
        });
    }
  },
  mounted() {
    this.$nextTick(() => {
      tippy('.tippy', {
        placement: 'top',
        arrow: false
      });
    });
  }
};
</script>

<style></style>
